import { FC, useState, useEffect } from 'react';

import { initUodkaCoreFirebaseUserClient } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import { useInviteForContactCtxAbsolutely, useUserCtx } from '@/utils/ctxs';
import { getFirebaseAuth } from '@/utils/GoogleCloud/firebase';
import { BusinessWithLogo } from '@/components/2_org/BusinessWithLogo';
import { SigninTemplate } from '@/components/3_template/SigninTemplate';
import { SignupTemplateForContact } from '@/components/3_template/SignupTemplate';

import { LinkTemplate } from '@/components/3_template/InviteForContactTemplate/LinkTemplate';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        textAlign: 'center',
    },
    already: {},
    joinButton: {},
}));

export const InviteForContactTemplateContent: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { inviteForContact, issuerProfile } = useInviteForContactCtxAbsolutely();
    const [isSigingup, setIsSigingup] = useState(false);
    const { userClient } = useUserCtx();

    // USEEFFECT
    useEffect(() => {
        const auth = getFirebaseAuth();
        auth.getRedirectResult().then((result) => {
            if (result.user && !userClient) {
                setIsSigingup(true);
            }
            console.log('result->', result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // HANDLER
    const handleRunSignup = async ({
        idToken,
        requestBusinessName,
        requestBusinessRemitterNameKana,
    }: {
        idToken: string;
        requestBusinessName: string;
        requestBusinessRemitterNameKana: string;
    }) => {
        const client = initUodkaCoreFirebaseUserClient({ firebaseUserIdToken: idToken });
        const { result } = await client.applyInviteForContactWithSigningUp({
            requestBusinessName,
            requestBusinessRemitterNameKana,
            issuerBusinessId: inviteForContact.business.id,
            issuerContactId: inviteForContact.contact.id,
            inviteCodeText: inviteForContact.codeText,
        });
        return result;
    };

    return !isSigingup ? (
        <div className={c.root}>
            <MuiTypography variant={'h1'} className={c.title}>
                {txt({
                    en: `Start doing business with ${inviteForContact.business.name}`,
                    ja: `${inviteForContact.business.name}との取引を開始する`,
                })}
            </MuiTypography>
            <BusinessWithLogo name={inviteForContact.business.name} logoSrc={issuerProfile?.logoSrc} />
            <div>{userClient ? <LinkTemplate /> : <SigninTemplate setIsSigingup={setIsSigingup} />}</div>
        </div>
    ) : (
        <SignupTemplateForContact runSignup={handleRunSignup} invite={inviteForContact} />
    );
};
InviteForContactTemplateContent.displayName = 'InviteForContactTemplateContent';
