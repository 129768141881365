import { FC } from 'react';

import SvgLink from '@/svgs/link';
import SvgLinked from '@/svgs/linked';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 55,
        position: 'relative',
        margin: '30px 0 20px 0',
        '&::before': {
            content: '""',
            display: 'block',
            width: 1,
            height: '100%',
            margin: '0 auto',
            background: theme.palette.text.primary,
            left: '50%',
        },
    },
    svgBox: {
        position: 'absolute',
        transform: 'translateY(-50%) translateX(-50%)',
        top: '50%',
        left: '50%',
        background: '#fff',
        padding: 4,
    },
    svg: {
        width: 18,
        height: 18,
    },
}));

export const LinkGraphic: FC<{
    isLinked: boolean;
}> = ({ isLinked }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <div className={c.svgBox}>{isLinked ? <SvgLinked className={c.svg} /> : <SvgLink className={c.svg} />}</div>
        </div>
    );
};
LinkGraphic.displayName = 'LinkGraphic';
