import { FC, ReactNode } from 'react';

import { useUserCtx, useErrorCtx } from '@/utils/ctxs';
import ErrorDisplay from '@/components/2_org/ErrorDisplay';
import FetchingText from '@/components/0_atom/FetchingText';

import SvgUodkaLogo from '@/svgs/uodkalogo';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiPaper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translateY(-50%) translateX(-50%)',
        // margin: 'auto',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        height: 20,
        marginTop: 110,
    },
    content: {
        marginTop: 55,
        padding: '26px 27px',
        position: 'relative',
        minWidth: 370,
        minHeight: 370,
        marginBottom: 75,
    },
    fetchingText: {
        marginTop: 64,
    },
    errorBox: {
        marginTop: 64,
    },
}));

export const GlobalTemplate: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { is404, is500 } = useErrorCtx();
    const { isFetching, userClient, firebaseUser } = useUserCtx();

    return (
        <div className={c.root}>
            <SvgUodkaLogo className={c.logo} />
            {isFetching ? (
                <FetchingText className={c.fetchingText} />
            ) : is500 ? (
                <div className={c.errorBox}>
                    <ErrorDisplay errorCode={500} />
                </div>
            ) : is404 ? (
                <div className={c.errorBox}>
                    <ErrorDisplay errorCode={404} />
                </div>
            ) : (
                <MuiPaper className={c.content} elevation={16}>
                    {children}
                </MuiPaper>
            )}
        </div>
    );
};
GlobalTemplate.displayName = 'GlobalTemplate';
