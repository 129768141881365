import { FC } from 'react';

import { useInviteForContactCtx } from '@/utils/ctxs';
import FetchingText from '@/components/0_atom/FetchingText';
import LanguageProvider from '@/providers/LanguageProvider';
import { LanguageSelector } from '@/components/2_org/LanguageSelector';

import { InviteForContactTemplateContent } from '@/components/3_template/InviteForContactTemplate/InviteForContactTemplateContent';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const InviteForContactTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { inviteForContact, issuerProfile } = useInviteForContactCtx();

    return (
        <div className={c.root}>
            {!inviteForContact || inviteForContact === 'fetching' || issuerProfile === 'fetching' ? (
                <FetchingText />
            ) : (
                <LanguageProvider inviteLanguage={inviteForContact.business.language}>
                    <InviteForContactTemplateContent />
                    <LanguageSelector />
                </LanguageProvider>
            )}
        </div>
    );
};
InviteForContactTemplate.displayName = 'InviteForContactTemplate';
