import { FC, useState, useEffect } from 'react';

import { ContactAPIForOwner } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import { useInviteForContactCtxAbsolutely } from '@/utils/ctxs';
import FetchingText from '@/components/0_atom/FetchingText';
import MutationButton from '@/components/0_atom/MutationButton';
import { ContactSelector } from '@/components/1_mol/Selector';

import {
    useBusinessWithContacts,
    MemberWithContact,
} from '@/components/3_template/InviteForContactTemplate/useBusinessWithContacts';
import { BusinessSelector } from '@/components/3_template/InviteForContactTemplate/BusinessSelector';
import { LinkGraphic } from '@/components/3_template/InviteForContactTemplate/LinkGraphic';
import { Linked } from '@/components/3_template/InviteForContactTemplate/Linked';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    businessSelectorBox: {
        textAlign: 'center',
    },
    businessSelector: {
        // margin: '0 auto',
        // display: 'flex',
    },
    contactSelector: {
        marginTop: 20,
    },
    contactSelectorLabel: {
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.text.disabled,
    },
    submitButtonBox: {
        textAlign: 'right',
        width: '100%',
        marginTop: 53,
    },
    submitButton: {
        height: 41,
        padding: '6px 24px',
    },
}));

export const LinkTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { inviteForContact } = useInviteForContactCtxAbsolutely();
    const { memberWithContacts } = useBusinessWithContacts({ inviteForContact });
    const [selectedMemberWithContact, setSelectedMemberWithContact] =
        useState<MemberWithContact | undefined>(undefined);
    const [selectedContactAPI, setSelectedContactAPI] = useState<ContactAPIForOwner | undefined>(undefined);
    const [isLinked, setIsLinked] = useState(false);

    // USEEFFECT
    useEffect(() => {
        setSelectedContactAPI(undefined);
    }, [selectedMemberWithContact]);

    // HANDLER
    const handleRunSubmit = async () => {
        if (selectedMemberWithContact?.ownerAPI) {
            const { result } = await selectedMemberWithContact.ownerAPI.coreClient.applyInviteForContact({
                issuerBusinessId: inviteForContact.business.id,
                issuerContactId: inviteForContact.contact.id,
                inviteCodeText: inviteForContact.codeText,
                requestContactId: selectedContactAPI?.id,
            });
            setIsLinked(true);
            return result;
        }
        throw new Error('LinkTemplate.handleRunSubmit: !(selectedMemberWithContact?.ownerAPI)');
    };

    return (
        <div className={c.root}>
            <LinkGraphic isLinked={isLinked} />
            {selectedMemberWithContact && isLinked ? (
                <Linked memberWithContact={selectedMemberWithContact} />
            ) : (
                <>
                    <div className={c.businessSelectorBox}>
                        {memberWithContacts ? (
                            <BusinessSelector
                                className={c.businessSelector}
                                memberWithContacts={memberWithContacts}
                                selectedMember={selectedMemberWithContact}
                                setSelectedMember={setSelectedMemberWithContact}
                            />
                        ) : (
                            <FetchingText />
                        )}
                    </div>
                    {selectedMemberWithContact?.ownerAPI && (
                        <div className={c.contactSelector}>
                            <MuiTypography className={c.contactSelectorLabel}>
                                {txt({
                                    en: 'Select contact to link',
                                    ja: 'リンクするコンタクトを選択',
                                })}
                            </MuiTypography>
                            <ContactSelector
                                contactItems={selectedMemberWithContact.ownerAPI.contactAPIs.map((contactAPI) => ({
                                    id: contactAPI.id,
                                    contact: contactAPI,
                                }))}
                                selectedContactItem={
                                    selectedContactAPI
                                        ? { id: selectedContactAPI.id, contact: selectedContactAPI }
                                        : undefined
                                }
                                setSelectedContactItem={(contactItem) =>
                                    setSelectedContactAPI(contactItem ? contactItem.contact : undefined)
                                }
                            />
                        </div>
                    )}
                    <div className={c.submitButtonBox}>
                        <MutationButton
                            className={c.submitButton}
                            disabled={!selectedMemberWithContact?.ownerAPI}
                            mutation={{
                                run: () => handleRunSubmit(),
                                description: txt({
                                    en: `link to ${inviteForContact.business.name} as a member`,
                                    ja: `${inviteForContact.business.name}とリンク`,
                                }),
                            }}
                            role={'submit'}
                        >
                            {txt({
                                en: 'Link',
                                ja: 'リンク',
                            })}
                        </MutationButton>
                    </div>
                </>
            )}
        </div>
    );
};
LinkTemplate.displayName = 'LinkTemplate';
