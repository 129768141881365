import { forwardRef, ReactNode, useState } from 'react';
import clsx from 'clsx';

import { AddMutationInput } from '@/utils/ctxs';
import { MenuItem } from '@/components/0_atom/Menu/MenuItem';

import SvgCheck from '@/svgs/fa-check-light';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
    subheader: {
        fontWeight: 600,
        // padding: '0 7px',
        lineHeight: '32px',
    },
    item: {
        padding: '5px 7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    itemWarn: {
        color: theme.palette.error.main,
    },
    icon: {
        color: 'inherit',
        width: 28,
        height: 28,
        minWidth: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 8,
        marginRight: 3,
    },
    textPrimaryTypography: {
        lineHeight: 1,
    },
    itemSelected: {},
    itemWithIcon: {},
    selectableItem: {
        paddingRight: 20,
    },
    iconPlaceHolder: {
        width: 28,
        height: 28,
        display: 'block',
        marginRight: 3,
    },
}));

export interface TextMenuItemProps {
    text: string;
    icon?: ReactNode;
    iconBackground?: string;
    isWarn?: boolean;
    onClick?: () => void;
    mutation?: AddMutationInput;
    isDisabled?: boolean;
    isSelected?: boolean;
    isSubheader?: boolean;
}

export const TextMenuItem = forwardRef<HTMLLIElement, TextMenuItemProps & { isWithIcon: boolean }>(
    (
        {
            text,
            icon: initialIcon,
            iconBackground,
            isWarn,
            onClick,
            mutation,
            isDisabled,
            isSelected,
            isSubheader,
            isWithIcon,
        },
        ref
    ) => {
        // STYLE
        const c = useStyles(useTheme());

        // HOOKS
        const [icon, setIcon] = useState(initialIcon);

        return (
            <MenuItem
                ref={ref}
                onClick={onClick}
                mutation={mutation}
                isDisabled={isDisabled}
                isSubheader={isSubheader}
                setIcon={setIcon}
                className={clsx(c.item, {
                    [c.itemWarn]: isWarn,
                    [c.itemSelected]: isSelected,
                    [c.itemWithIcon]: isWithIcon,
                    [c.selectableItem]: !icon && isWithIcon,
                })}
            >
                {isSubheader ? (
                    text
                ) : (
                    <>
                        {isSelected ? (
                            <SvgCheck className={c.icon} />
                        ) : icon ? (
                            <MuiListItemIcon className={c.icon} style={{ background: iconBackground }}>
                                {icon}
                            </MuiListItemIcon>
                        ) : isWithIcon ? (
                            <span className={c.iconPlaceHolder} />
                        ) : (
                            <></>
                        )}
                        <MuiListItemText
                            primary={text}
                            primaryTypographyProps={{
                                className: c.textPrimaryTypography,
                                variant: 'body2',
                            }}
                        />
                    </>
                )}
            </MenuItem>
        );
    }
);
