import { FC } from 'react';

import { useLanguage } from '@/utils/customHooks';
import { useUserCtx, useHealthCtx, useMutationsCtx, ProcessingMutation } from '@/utils/ctxs';
import Health, { ServerState } from '@/components/2_org/NetworkIndicator/Health';
import Slot from '@/components/2_org/NetworkIndicator/Slot';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiGrow from '@material-ui/core/Grow';
import MuiPaper from '@material-ui/core/Paper';
import MuiList from '@material-ui/core/List';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        zIndex: 10000,
        top: 14,
        right: 14,
        padding: '15px 16px',
        background: '#555',
        color: 'rgba(255,255,255,.8)',
        maxWidth: 240,
    },
    newWork: {},
    newWorkTitle: {},
    newWorkMessage: {},
    newWorkUrl: {},
    slots: {
        paddingBottom: 0,
    },
}));

const NetworkIndicator: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { core, profile } = useHealthCtx();
    const { isFirebaseError } = useUserCtx();
    const { slot1, slot2, slot3, slot4 } = useMutationsCtx();
    const { txt } = useLanguage();

    // DATA
    const serverState: ServerState = (() => {
        if (isFirebaseError) {
            return {
                status: 'authFetchError' as const,
                message: txt({
                    en: "Couldn't connect to the authentication server. Please check your network connection and reload.",
                    ja: '認証サーバーに接続できませんでした。ネットワーク接続を確認し、リロードしてください。',
                }),
            };
        }
        if (core.health && profile.health) {
            const status = (() => {
                if (core.health.code === 'ok' && profile.health.code === 'ok') {
                    return 'ok' as const;
                }
                return 'outOfService' as const;
            })();
            return {
                status,
                message: core.health.message || profile.health.message,
                url: core.health.url || profile.health.url,
            };
        }
        if (core.fetchStatus === 'fetchError' || profile.fetchStatus === 'fetchError') {
            return {
                status: 'fetchError' as const,
                message: core.health?.message || profile.health?.message,
                url: core.health?.url || profile.health?.url,
            };
        }
        return {
            status: 'fetching' as const,
        };
    })();
    const slots = [slot1, slot2, slot3, slot4].reduce((acc, prev) => {
        if (prev) {
            return [...acc, prev].sort((a, b) => (a.addedAt > b.addedAt ? -1 : 1));
        }
        return acc;
    }, [] as ProcessingMutation[]);
    // const slots: ProcessingMutation[] = [
    //     {
    //         description: '新しいビジネス「ノーシフト 」を作成',
    //         retry: () => undefined,
    //         cancel: () => undefined,
    //         addedAt: new Date(),
    //         status: 'success',
    //     },
    //     {
    //         description: '新しいビジネス「ノーシフト 」を作成',
    //         retry: () => undefined,
    //         cancel: () => undefined,
    //         addedAt: new Date(),
    //         status: 'canceled',
    //     },
    //     {
    //         description: '新しいビジネス「ノーシフト 」を作成',
    //         retry: () => undefined,
    //         cancel: () => undefined,
    //         addedAt: new Date(),
    //         status: 'fetchError',
    //     },
    //     {
    //         description: '新しいビジネス「ノーシフト 」を作成',
    //         retry: () => undefined,
    //         cancel: () => undefined,
    //         addedAt: new Date(),
    //         status: 'fetching',
    //     },
    //     {
    //         description: '新しいビジネス「ノーシフト 」を作成',
    //         retry: () => undefined,
    //         cancel: () => undefined,
    //         addedAt: new Date(),
    //         status: 'incorrectOldValue',
    //     },
    //     {
    //         description: '新しいビジネス「ノーシフト 」を作成',
    //         retry: () => undefined,
    //         cancel: () => undefined,
    //         addedAt: new Date(),
    //         status: 'intervalTooShort',
    //     },
    // ];

    return (
        <MuiGrow in={serverState.status !== 'ok' || slots.length > 0}>
            <MuiPaper className={c.root} elevation={16}>
                <Health serverState={serverState} />
                {slots.length > 0 && (
                    <MuiList className={c.slots}>
                        {slots.map((slot, i) => (
                            <Slot key={i} slot={slot} />
                        ))}
                    </MuiList>
                )}
            </MuiPaper>
        </MuiGrow>
    );
};
NetworkIndicator.displayName = 'NetworkIndicator';
export default NetworkIndicator;
