import { FC, useState, useEffect } from 'react';

import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';
import Button from '@/components/0_atom/Button';
import CheckList from '@/components/1_mol/CheckList';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiFade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 312,
    },
    heading: {
        textAlign: 'center',
    },
    p1: {
        marginTop: 27,
        lineHeight: '1.5',
        '& a': {
            color: '#0BA9CC',
            fontWeight: 600,
        },
    },
    p2: {
        marginTop: 17,
        lineHeight: '1.5',
    },
    checkList: {
        marginTop: 15,
        color: theme.palette.primary.main,
        '& svg': {
            color: theme.palette.primary.main,
        },
    },
    goToConsoleBox: {
        marginTop: 34,
        textAlign: 'right',
        '& > a': {
            padding: '6px 24px',
        },
    },
}));

export const Welcome: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isIn, setIsIn] = useState(false);

    // USEEFFECT
    useEffect(() => {
        setTimeout(() => {
            if (!isIn) {
                setIsIn(true);
            }
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MuiFade in={isIn} timeout={400}>
            <div className={c.root}>
                <MuiTypography variant={'h1'} className={c.heading}>
                    {txt({ en: 'Welcome to Uodka', ja: 'Uodkaへようこそ' })}
                </MuiTypography>
                <MuiTypography variant={'body2'} className={c.p1}>
                    {txt({
                        en: "Thank you for starting to use Uodka. Uodka is currently available as an early access program, and we'd love to have your feedback for our development team. If you like, please join our ",
                        ja: 'Uodkaを利用開始してくださり、ありがとうございます。Uodkaは現在、早期試用プログラムとして提供しています。開発チームへのフィードバックをいただければ大変ありがたいです。ぜひ',
                    })}
                    <a href={'later'} target={'_blank'} rel="noreferrer noopener">
                        {txt({
                            en: 'user community on Slack',
                            ja: 'Slackのユーザーコミュニティ',
                        })}
                    </a>
                    {txt({
                        en: '.',
                        ja: 'にご参加ください。',
                    })}
                </MuiTypography>
                <MuiTypography variant={'body2'} className={c.p2}>
                    {txt({
                        en: 'The following features are currently available in Uodka. Enjoy a seamless experience.',
                        ja: 'Uodkaでは現在、以下の機能をご利用いただけます。シームレスな体験をお楽しみください。',
                    })}
                </MuiTypography>
                <CheckList
                    className={c.checkList}
                    items={[
                        {
                            label: txt({
                                en: 'Bank transfer',
                                ja: '銀行振込',
                            }),
                            isOk: true,
                        },
                        {
                            label: txt({
                                en: 'Issue a dedicated deposit account for each contact',
                                ja: '各コンタクト（取引先）専用の入金口座の発行',
                            }),
                            isOk: true,
                        },
                        {
                            label: txt({
                                en: 'Issue bills / Make a payment to received bills',
                                ja: '請求書の発行・受け取った請求書にその場で支払い',
                            }),
                            isOk: true,
                        },
                    ]}
                />
                <div className={c.goToConsoleBox}>
                    <Button href={getEnvVariables().consoleRootUrl} role={'submit'}>
                        {txt({ en: 'Open Console', ja: 'コンソールへ' })}
                    </Button>
                </div>
            </div>
        </MuiFade>
    );
};
Welcome.displayName = 'Welcome';
