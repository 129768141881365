import { FC, ReactNode, useMemo } from 'react';

import { HealthCtx } from '@/utils/ctxs';
import { getUodkaCoreHealth } from '@/utils/UodkaClients/Core';
import { getUodkaProfileHealth } from '@/utils/UodkaClients/Profile';
import { useHealth } from '@/providers/HealthProvider/useHealth';

const HealthProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const coreHealth = useHealth(getUodkaCoreHealth);
    const profileHealth = useHealth(getUodkaProfileHealth);

    // MEMO
    const content = useMemo(
        () => <HealthCtx.Provider value={{ core: coreHealth, profile: profileHealth }}>{children}</HealthCtx.Provider>,
        [children, coreHealth, profileHealth]
    );

    return content;
};
HealthProvider.displayName = 'HealthProvider';
export default HealthProvider;
