import { FC, useState } from 'react';

import { getValidRemitterName, getKana, MutationValidationResult } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import FormItem from '@/components/0_atom/FormItem';
import { TextInput } from '@/components/0_atom/Input';
import MutationButton from '@/components/0_atom/MutationButton';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
    remitterNameKanaDescription: {
        marginTop: 11,
    },
    remitterNameKanaDescriptionLabel: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        display: 'block',
        marginBottom: 3,
        color: theme.palette.text.disabled,
    },
    remitterNameKanaDescriptionValue: {
        display: 'block',
        lineHeight: '1.5',
        fontWeight: 600,
    },
    submitButtonBox: {
        textAlign: 'right',
        marginTop: 55,
    },
    submitButton: {
        padding: '10px 24px',
        width: '50%',
    },
}));

export const NewBusinessForm: FC<{
    initialName: string;
    onSubmit: (input: {
        newBusinessName: string;
        newBusinessRemitterNameKana: string;
    }) => Promise<MutationValidationResult>;
}> = ({ initialName, onSubmit }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [name, setName] = useState<string | undefined>(initialName);
    const [remitterNameKana, setRemitterNameKana] = useState<string | undefined>(undefined);

    // DATA
    const { isEntered, isValid, halfWidthKatakana } = (() => {
        if (remitterNameKana) {
            const { isValid, halfWidthKatakana } = getValidRemitterName(remitterNameKana);
            return {
                isEntered: true,
                isValid,
                halfWidthKatakana,
            };
        }
        return {
            isEntered: false,
            isValid: false,
            halfWidthKatakana: undefined,
        };
    })();
    const remitterNameKanaMessages = [
        {
            isError: isEntered && !isValid,
            message: txt({
                en: 'Remitter name of account should be within 19 characters in Hiragana, "（" or "）" (counting turbidity marks and half turbidity marks as one character)',
                ja: '口座名義は、カナと全角括弧のいずれかで、19文字以内（濁点・半濁点を１文字としてカウント）の必要があります。',
            }),
        },
    ];

    // HANDLER
    const run = async () => {
        if (name && isValid && halfWidthKatakana) {
            const result = await onSubmit({ newBusinessName: name, newBusinessRemitterNameKana: halfWidthKatakana });
            return result;
        }
        throw new Error('NewBusinessForm.run: !(name && isValid && halfWidthKatakana)');
    };

    return (
        <div className={c.root}>
            <FormItem isRequired labelText={txt({ en: 'Name', ja: '名称' })} isSatisfied={!!name} isError={undefined}>
                <TextInput value={name} setValue={setName} fullWidth isSatisfied={!!name} />
            </FormItem>
            <FormItem
                isRequired
                labelText={txt({ en: 'Remitter name', ja: '口座名義（カナ）' })}
                isSatisfied={!!remitterNameKana && !remitterNameKanaMessages.find(({ isError }) => isError)?.isError}
                isError={remitterNameKanaMessages.find(({ isError }) => isError)?.isError}
            >
                <TextInput
                    value={remitterNameKana}
                    setValue={setRemitterNameKana}
                    messages={remitterNameKanaMessages}
                    fullWidth
                    error={remitterNameKanaMessages.find(({ isError }) => isError)?.isError}
                    isSatisfied={
                        !!remitterNameKana && !remitterNameKanaMessages.find(({ isError }) => isError)?.isError
                    }
                />
                <div className={c.remitterNameKanaDescription}>
                    <span className={c.remitterNameKanaDescriptionLabel}>
                        {txt({
                            en: 'Remitter name of account',
                            ja: '振り込み時の名義人名',
                        })}
                    </span>
                    <span className={c.remitterNameKanaDescriptionValue}>
                        {remitterNameKana ? getKana(remitterNameKana)?.fullWidthKatakana || '-' : '-'}
                    </span>
                </div>
                <div className={c.remitterNameKanaDescription}>
                    <span className={c.remitterNameKanaDescriptionLabel}>
                        {txt({
                            en: "Deposit account name (Can't change it later)",
                            ja: '振込入金口座の名義人名 (後で変更できません)',
                        })}
                    </span>
                    <span className={c.remitterNameKanaDescriptionValue}>
                        {`${
                            remitterNameKana ? getKana(remitterNameKana)?.fullWidthKatakana || '-' : '-'
                        } （ニュウキンコウザ／（カ）ノーシフト）`}
                    </span>
                </div>
            </FormItem>
            <div className={c.submitButtonBox}>
                <MutationButton
                    mutation={{
                        run,
                        description: txt({
                            en: `Create new business "${name}"`,
                            ja: `新しいビジネス「${name}」を作成`,
                        }),
                    }}
                    role={'submit'}
                    className={c.submitButton}
                >
                    {txt({
                        en: 'Create business',
                        ja: 'ビジネスを作成',
                    })}
                </MutationButton>
            </div>
        </div>
    );
};
NewBusinessForm.displayName = 'NewBusinessForm';
