import { useState, useEffect, useCallback } from 'react';

import { initUodkaCorePublicClient, InviteForMember } from '@/utils/UodkaClients/Core';

export const useInviteForMember = ({ businessId, inviteCodeText }: { businessId: string; inviteCodeText: string }) => {
    // HOOKS
    const [invite, setInvite] = useState<InviteForMember | undefined | 'fetching'>('fetching');
    const fetchInvite = useCallback(async () => {
        const client = initUodkaCorePublicClient();
        const fetchedInvite = await client.getInviteForMember({ businessId, inviteCodeText });
        setInvite(fetchedInvite);
    }, [businessId, inviteCodeText]);

    // USEEFFECT
    useEffect(() => {
        fetchInvite();
    }, [fetchInvite]);

    return {
        inviteForMember: invite,
    };
};
