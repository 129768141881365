import { FC } from 'react';

import { useInviteForMemberCtx } from '@/utils/ctxs';
import FetchingText from '@/components/0_atom/FetchingText';
import LanguageProvider from '@/providers/LanguageProvider';
import { LanguageSelector } from '@/components/2_org/LanguageSelector';

import { InviteForMemberTemplateContent } from '@/components/3_template/InviteForMemberTemplate/InviteForMemberTemplateContent';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const InviteForMemberTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { inviteForMember, issuerProfile } = useInviteForMemberCtx();
    console.log('inviteForMember->', inviteForMember);
    return (
        <div className={c.root}>
            {!inviteForMember || inviteForMember === 'fetching' || issuerProfile === 'fetching' ? (
                <FetchingText />
            ) : (
                <LanguageProvider inviteLanguage={inviteForMember.business.language}>
                    <InviteForMemberTemplateContent />
                    <LanguageSelector />
                </LanguageProvider>
            )}
        </div>
    );
};
InviteForMemberTemplate.displayName = 'InviteForMemberTemplate';
