import { FC } from 'react';
import clsx from 'clsx';

import { useLanguage } from '@/utils/customHooks';
import { ButtonWithMenu } from '@/components/1_mol/ButtonWithMenu';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';

import { MemberWithContact } from '@/components/3_template/InviteForContactTemplate/useBusinessWithContacts';

import SvgChevronUp from '@/svgs/fa-chevron-up-solid';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 'solid 2px rgba(0,0,0,.05)',
        position: 'relative',
        background: '#fff',
        // padding: '21px 17px',
        paddingRight: 48,
        minWidth: 237,
        minHeight: 54,
        justifyContent: 'flex-start',
        '& .MuiButton-endIcon': {
            position: 'absolute',
            right: 14,
            width: 12,
            height: 'auto',
            transform: 'rotate(180deg)',
            color: theme.palette.text.disabled,
        },
        '& .MuiButton-label': {
            fontWeight: 600,
        },
    },
    menuPaper: {
        minWidth: 237,
    },
    item: {
        display: 'block',
    },
    business: {
        // display: 'block',
    },
    caption: {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 600,
        marginLeft: 38,
    },
}));

export const BusinessSelector: FC<{
    className?: string;
    memberWithContacts: MemberWithContact[];
    selectedMember: MemberWithContact | undefined;
    setSelectedMember: (selectedMember: MemberWithContact | undefined) => void;
}> = ({ className, memberWithContacts, selectedMember, setSelectedMember }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <ButtonWithMenu
            menuEmptyPlaceholder={txt({
                en: 'You have no business. Please create a business in the console first.',
                ja: 'ビジネスがありません。先にコンソールでビジネスを作成してください。',
            })}
            className={clsx(c.root, className)}
            items={memberWithContacts.map(({ member, profile, ownerAPI }) => {
                const isAlreadyLinked = ownerAPI?.isLinked;
                return {
                    children: (
                        <div className={c.item}>
                            <ContactNameWithIcon
                                contact={{ name: member.business.name }}
                                profile={profile}
                                className={c.business}
                            />
                            {!ownerAPI ? (
                                <MuiTypography className={c.caption}>
                                    {txt({
                                        en: 'Only owner can operate',
                                        ja: '権限がありません',
                                    })}
                                </MuiTypography>
                            ) : isAlreadyLinked ? (
                                <MuiTypography className={c.caption}>
                                    {txt({
                                        en: 'Already linked',
                                        ja: 'リンク済み',
                                    })}
                                </MuiTypography>
                            ) : (
                                <></>
                            )}
                        </div>
                    ),
                    onClick: () => setSelectedMember({ member, profile, ownerAPI }),
                    isDisabled: !ownerAPI || isAlreadyLinked,
                };
            })}
            endIcon={<SvgChevronUp />}
            menuPaperClassName={c.menuPaper}
        >
            {selectedMember ? (
                <ContactNameWithIcon
                    contact={{ name: selectedMember.member.business.name }}
                    profile={selectedMember.profile}
                />
            ) : (
                txt({ en: 'Select Business', ja: 'ビジネスを選択' })
            )}
        </ButtonWithMenu>
    );
};
BusinessSelector.displayName = 'BusinessSelector';
