import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        // <svg className={className} viewBox="0 0 863 216" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path
        //         d="M83.048 215.728C56.936 215.728 36.584 208.24 21.992 193.264C7.40001 178.288 0.104004 156.496 0.104004 127.888V12.4004H21.416V127.024C21.416 150.448 26.696 167.92 37.256 179.44C47.816 190.96 63.08 196.72 83.048 196.72C103.208 196.72 118.568 190.96 129.128 179.44C139.688 167.92 144.968 150.448 144.968 127.024V12.4004H165.704V127.888C165.704 156.496 158.408 178.288 143.816 193.264C129.416 208.24 109.16 215.728 83.048 215.728Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         d="M530.752 0.304688V214.001H511.168V184.049C505.024 194.225 496.864 202.001 486.688 207.377C476.704 212.753 465.376 215.441 452.704 215.441C438.496 215.441 425.632 212.177 414.112 205.649C402.592 199.121 393.568 190.001 387.04 178.289C380.512 166.577 377.248 153.233 377.248 138.257C377.248 123.281 380.512 109.937 387.04 98.2247C393.568 86.5127 402.592 77.4887 414.112 71.1527C425.632 64.6247 438.496 61.3607 452.704 61.3607C464.992 61.3607 476.032 63.9527 485.824 69.1367C495.808 74.1287 503.968 81.5207 510.304 91.3127V0.304688H530.752ZM454.432 197.297C464.992 197.297 474.496 194.897 482.944 190.097C491.584 185.105 498.304 178.097 503.104 169.073C508.096 160.049 510.592 149.777 510.592 138.257C510.592 126.737 508.096 116.465 503.104 107.441C498.304 98.4167 491.584 91.5047 482.944 86.7047C474.496 81.7127 464.992 79.2167 454.432 79.2167C443.68 79.2167 433.984 81.7127 425.344 86.7047C416.896 91.5047 410.176 98.4167 405.184 107.441C400.384 116.465 397.984 126.737 397.984 138.257C397.984 149.777 400.384 160.049 405.184 169.073C410.176 178.097 416.896 185.105 425.344 190.097C433.984 194.897 443.68 197.297 454.432 197.297Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         d="M631.672 137.969L595.96 170.801V214.001H575.512V0.304688H595.96V145.169L686.392 62.5127H711.736L646.936 124.433L717.784 214.001H692.728L631.672 137.969Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         d="M801.504 61.3613C821.28 61.3613 836.448 66.3533 847.008 76.3373C857.568 86.1293 862.848 100.721 862.848 120.113V214.001H843.264V190.385C838.656 198.257 831.84 204.401 822.816 208.817C813.984 213.233 803.424 215.441 791.136 215.441C774.24 215.441 760.8 211.409 750.816 203.345C740.832 195.281 735.84 184.625 735.84 171.377C735.84 158.513 740.448 148.145 749.664 140.273C759.072 132.401 773.952 128.465 794.304 128.465H842.4V119.249C842.4 106.193 838.752 96.3053 831.456 89.5853C824.16 82.6733 813.504 79.2173 799.488 79.2173C789.888 79.2173 780.672 80.8493 771.84 84.1133C763.008 87.1853 755.424 91.5053 749.088 97.0733L739.872 81.8093C747.552 75.2813 756.768 70.2893 767.52 66.8333C778.272 63.1853 789.6 61.3613 801.504 61.3613ZM794.304 199.313C805.824 199.313 815.712 196.721 823.968 191.537C832.224 186.161 838.368 178.481 842.4 168.497V143.729H794.88C768.96 143.729 756 152.753 756 170.801C756 179.633 759.36 186.641 766.08 191.825C772.8 196.817 782.208 199.313 794.304 199.313Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         d="M273.432 215.441C258.84 215.441 245.688 212.177 233.976 205.649C222.264 198.929 213.048 189.713 206.328 178.001C199.608 166.289 196.248 153.041 196.248 138.257C196.248 123.473 199.608 110.225 206.328 98.5133C213.048 86.8013 222.264 77.6813 233.976 71.1533C245.688 64.6253 258.84 61.3613 273.432 61.3613C288.024 61.3613 301.176 64.6253 312.888 71.1533C324.6 77.6813 333.72 86.8013 340.248 98.5133C346.968 110.225 350.328 123.473 350.328 138.257C350.328 153.041 346.968 166.289 340.248 178.001C333.72 189.713 324.6 198.929 312.888 205.649C301.176 212.177 288.024 215.441 273.432 215.441ZM273.432 197.297C284.184 197.297 293.784 194.897 302.232 190.097C310.872 185.105 317.592 178.097 322.392 169.073C327.192 160.049 329.592 149.777 329.592 138.257C329.592 126.737 327.192 116.465 322.392 107.441C317.592 98.4173 310.872 91.5053 302.232 86.7053C293.784 81.7133 284.184 79.2173 273.432 79.2173C262.68 79.2173 252.984 81.7133 244.344 86.7053C235.896 91.5053 229.176 98.4173 224.184 107.441C219.384 116.465 216.984 126.737 216.984 138.257C216.984 149.777 219.384 160.049 224.184 169.073C229.176 178.097 235.896 185.105 244.344 190.097C252.984 194.897 262.68 197.297 273.432 197.297Z"
        //         fill="currentColor"
        //     />
        // </svg>
        <svg className={className} viewBox="0 0 868 217" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M85.7602 216.304C58.8802 216.304 37.9522 208.624 22.9762 193.264C8.00021 177.904 0.512207 155.824 0.512207 127.024V12.4004H29.3122V125.872C29.3122 169.072 48.2242 190.672 86.0482 190.672C104.48 190.672 118.592 185.392 128.384 174.832C138.176 164.08 143.072 147.76 143.072 125.872V12.4004H171.008V127.024C171.008 156.016 163.52 178.192 148.544 193.552C133.568 208.72 112.64 216.304 85.7602 216.304Z"
                fill="currentColor"
            />
            <path
                d="M537.208 0.304688V214.001H510.712V189.809C504.568 198.257 496.792 204.689 487.384 209.105C477.976 213.521 467.608 215.729 456.28 215.729C441.496 215.729 428.248 212.465 416.536 205.937C404.824 199.409 395.608 190.289 388.888 178.577C382.36 166.673 379.096 153.041 379.096 137.681C379.096 122.321 382.36 108.785 388.888 97.0727C395.608 85.3607 404.824 76.2407 416.536 69.7127C428.248 63.1847 441.496 59.9207 456.28 59.9207C467.224 59.9207 477.304 62.0327 486.52 66.2567C495.736 70.2887 503.416 76.3367 509.56 84.4007V0.304688H537.208ZM458.584 191.537C468.184 191.537 476.92 189.329 484.792 184.913C492.664 180.305 498.808 173.969 503.224 165.905C507.64 157.649 509.848 148.241 509.848 137.681C509.848 127.121 507.64 117.809 503.224 109.745C498.808 101.489 492.664 95.1527 484.792 90.7367C476.92 86.3207 468.184 84.1127 458.584 84.1127C448.792 84.1127 439.96 86.3207 432.088 90.7367C424.408 95.1527 418.264 101.489 413.656 109.745C409.24 117.809 407.032 127.121 407.032 137.681C407.032 148.241 409.24 157.649 413.656 165.905C418.264 173.969 424.408 180.305 432.088 184.913C439.96 189.329 448.792 191.537 458.584 191.537Z"
                fill="currentColor"
            />
            <path
                d="M635.536 142.865L603.856 172.241V214.001H576.208V0.304688H603.856V137.393L687.088 61.3607H720.496L656.272 124.433L726.832 214.001H692.848L635.536 142.865Z"
                fill="currentColor"
            />
            <path
                d="M801.792 59.9199C822.912 59.9199 839.04 65.1039 850.176 75.4719C861.504 85.8399 867.168 101.296 867.168 121.84V214H840.96V193.84C836.352 200.944 829.728 206.416 821.088 210.256C812.64 213.904 802.56 215.728 790.848 215.728C773.76 215.728 760.032 211.6 749.664 203.344C739.488 195.088 734.4 184.24 734.4 170.8C734.4 157.36 739.296 146.608 749.088 138.544C758.88 130.288 774.432 126.16 795.744 126.16H839.52V120.688C839.52 108.784 836.064 99.6639 829.152 93.3279C822.24 86.9919 812.064 83.8239 798.624 83.8239C789.6 83.8239 780.768 85.3599 772.128 88.4319C763.488 91.3119 756.192 95.2479 750.24 100.24L738.72 79.5039C746.592 73.1679 756 68.3679 766.944 65.1039C777.888 61.6479 789.504 59.9199 801.792 59.9199ZM795.456 194.416C806.016 194.416 815.136 192.112 822.816 187.504C830.496 182.704 836.064 175.984 839.52 167.344V146.032H796.896C773.472 146.032 761.76 153.904 761.76 169.648C761.76 177.328 764.736 183.376 770.688 187.792C776.64 192.208 784.896 194.416 795.456 194.416Z"
                fill="currentColor"
            />
            <path
                d="M277.584 215.728C262.416 215.728 248.784 212.368 236.688 205.648C224.592 198.928 215.088 189.712 208.176 178C201.456 166.096 198.096 152.656 198.096 137.68C198.096 122.704 201.456 109.36 208.176 97.6479C215.088 85.7439 224.592 76.5279 236.688 69.9999C248.784 63.2799 262.416 59.9199 277.584 59.9199C292.752 59.9199 306.288 63.2799 318.192 69.9999C330.288 76.5279 339.696 85.7439 346.416 97.6479C353.328 109.36 356.784 122.704 356.784 137.68C356.784 152.656 353.328 166.096 346.416 178C339.696 189.712 330.288 198.928 318.192 205.648C306.288 212.368 292.752 215.728 277.584 215.728ZM277.584 191.536C287.376 191.536 296.112 189.328 303.792 184.912C311.664 180.304 317.808 173.968 322.224 165.904C326.64 157.648 328.848 148.24 328.848 137.68C328.848 127.12 326.64 117.808 322.224 109.744C317.808 101.488 311.664 95.1519 303.792 90.7359C296.112 86.3199 287.376 84.1119 277.584 84.1119C267.792 84.1119 258.96 86.3199 251.088 90.7359C243.408 95.1519 237.264 101.488 232.656 109.744C228.24 117.808 226.032 127.12 226.032 137.68C226.032 148.24 228.24 157.648 232.656 165.904C237.264 173.968 243.408 180.304 251.088 184.912C258.96 189.328 267.792 191.536 277.584 191.536Z"
                fill="currentColor"
            />
        </svg>

        // <svg className={className} viewBox="0 0 3366 899" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path
        //         d="M2361.77 0.914062H2258.99V874.532H2361.77V683.964L2470.97 575.832L2671.17 874.532H2798.58L2544.84 503.031L2793.22 252.508H2656.19L2361.77 550.137V0.914062Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         d="M0 0.914062H102.779V361.245V592.962C102.779 593.319 102.78 593.675 102.782 594.032C103.404 705.292 200.947 795.307 321.183 795.307C441.419 795.307 538.963 705.292 539.586 594.032C539.588 593.675 539.588 593.319 539.588 592.962V361.245V0.914062H642.366V594.032H642.272C638.114 760.268 495.95 893.803 321.183 893.803C146.417 893.803 4.25304 760.268 0.0936632 594.032H0V0.914062Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M1079.18 893.803C1255.38 893.803 1398.22 746.17 1398.22 564.055C1398.22 381.94 1255.38 234.307 1079.18 234.307C902.974 234.307 760.134 381.94 760.134 564.055C760.134 746.17 902.974 893.803 1079.18 893.803ZM1079.18 801.73C1199.8 801.73 1297.58 694.84 1297.58 562.984C1297.58 431.128 1199.8 324.238 1079.18 324.238C958.554 324.238 860.771 431.128 860.771 562.984C860.771 694.84 958.554 801.73 1079.18 801.73Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M2003.97 557.507C2004.11 561.097 2004.18 564.708 2004.18 568.339L2003.97 557.507Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M2102.68 5.19727H1999.9V348.796C1948.92 281.167 1874.76 238.59 1792.2 238.59H1772.93C1615.65 238.59 1488.15 386.224 1488.15 568.339C1488.15 750.453 1615.65 898.087 1772.93 898.087C1776.19 898.087 1779.44 898.023 1782.68 897.897C1785.84 898.023 1789.01 898.087 1792.2 898.087C1877.1 898.087 1953.13 853.06 2004.18 782.065V880.957H2102.68V5.19727ZM2004.18 568.339C2004.18 698.421 1913.11 803.873 1800.77 803.873C1799.14 803.873 1797.53 803.851 1795.91 803.807C1795.03 803.783 1794.15 803.753 1793.27 803.716C1792.92 803.701 1792.56 803.685 1792.2 803.668C1786.06 803.373 1779.99 802.764 1774 801.852C1773.64 801.798 1773.29 801.742 1772.93 801.686C1673.76 785.972 1597.35 687.487 1597.35 568.339C1597.35 449.19 1673.76 350.705 1772.93 334.991C1773.29 334.935 1773.64 334.879 1774 334.825C1779.99 333.913 1786.06 333.304 1792.2 333.009C1792.56 332.992 1792.92 332.976 1793.27 332.961C1794.15 332.924 1795.03 332.894 1795.91 332.87C1797.53 332.826 1799.14 332.804 1800.77 332.804C1909.98 332.804 1999.08 432.453 2003.97 557.507C2004.11 561.097 2004.18 564.708 2004.18 568.339Z"
        //         fill="currentColor"
        //     />
        //     <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M3366 478.406V876.673H3267.5V774.057C3262.14 784.988 3255.6 795.437 3248.02 805.303C3245.1 809.097 3242.03 812.804 3238.81 816.42C3221.48 835.88 3199.88 852.673 3175.12 865.894C3140.43 884.416 3099.54 895.927 3055.52 897.942C2969.45 894.003 2895.35 853.758 2856.17 795.799C2850.6 787.561 2845.74 778.965 2841.64 770.064C2831.8 748.647 2826.41 725.467 2826.41 701.267C2826.41 628.542 2845.68 578.514 2886.25 543.492C2926.81 508.469 2997.3 481.266 3065.16 478.58C3073.31 478.309 3084.53 478.413 3094.22 478.503C3098.53 478.543 3102.54 478.58 3105.84 478.58C3167.64 478.58 3224.15 493.105 3267.5 517.117V478.406C3260.01 372.415 3182.16 326.379 3104.7 326.379C3027.25 326.379 2960.23 360.634 2928.05 410.452L2849.97 352.074C2898.72 282.715 2984.28 234.307 3084.36 234.307C3122.9 234.307 3141.1 234.731 3176.44 241.093C3211.78 247.456 3361.72 287.837 3366 478.406ZM3245.17 593.62C3217.6 579.579 3190.42 570.729 3147.6 564.306C3129.02 561.52 3085.5 562.088 3085.5 562.088C3052.52 562.088 3022.09 570.152 2997.68 583.746C2973.27 597.34 2961.31 612.483 2961.31 612.483C2942.01 634.265 2931.33 660.046 2931.33 687.349C2931.33 714.653 2944.18 752.616 2976.36 776.424C2999.29 793.384 3032.5 804.046 3069.44 804.046C3106.38 804.046 3129.02 794.794 3129.02 794.794C3142.17 789.686 3154.43 783.869 3165.65 777.435C3176.77 771.055 3183.48 765.976 3194.2 757.854L3194.48 757.644C3258.94 708.838 3267.5 620.797 3267.5 620.797L3267.44 606.438C3260.49 601.916 3253.06 597.635 3245.17 593.62Z"
        //         fill="currentColor"
        //     />
        // </svg>
    );
};
Svg.displayName = 'svg/uodkalogo';
export default Svg;
