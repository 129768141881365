import { createContext, useContext } from 'react';

import { InviteForMember } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';

type InviteForMemberCtxType = {
    inviteForMember: InviteForMember | undefined | 'fetching';
    issuerProfile: BusinessProfile | undefined | 'fetching';
};
export const InviteForMemberCtx = createContext<InviteForMemberCtxType>({
    inviteForMember: 'fetching',
    issuerProfile: 'fetching',
});
export const useInviteForMemberCtx = () => {
    return useContext(InviteForMemberCtx);
};
export const useInviteForMemberCtxAbsolutely = () => {
    const { inviteForMember, issuerProfile } = useInviteForMemberCtx();
    if (!inviteForMember || inviteForMember === 'fetching' || issuerProfile === 'fetching') {
        throw new Error(
            "useInviteForMemberCtxAbsolutely: (!inviteForMember || inviteForMember === 'fetching' || issuerProfile === 'fetching')"
        );
    }
    return { inviteForMember, issuerProfile };
};
