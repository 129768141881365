import { FC, useState } from 'react';

import { useUserCtx, useErrorCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import FirebaseAuth from '@/components/0_atom/FirebaseAuth';
import { TosModal } from '@/components/2_org/TosModal';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 84,
    },
    title: {
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        marginBottom: 10,
        paddingLeft: 3,
    },
    buttons: {
        display: 'flex',
        '& > button:first-child': {
            marginRight: 10,
        },
    },
    cardButton: {
        display: 'block',
        border: 'solid 2px rgba(0,0,0,.03)',
        textAlign: 'left',
        padding: '14px 19px',
        width: 174,
    },
    buttonTitle: {
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        display: 'block',
    },
    buttonDescription: {
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        color: theme.palette.text.disabled,
        display: 'block',
        marginTop: 7,
    },
    firebaseAuthBox: {
        width: 174 * 2 + 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 14,
    },
    tosButton: {
        fontWeight: 600,
        // color: theme.palette.text.disabled,
        fontSize: theme.typography.body2.fontSize,
        marginTop: 14,
    },
    continueBox: {
        textAlign: 'center',
        marginBottom: 23,
    },
}));

export const SigninTemplate: FC<{
    setIsSigingup: (isSigingup: boolean) => void;
}> = ({ setIsSigingup }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { firebaseUser } = useUserCtx();
    const { txt } = useLanguage();
    const [isSigningin, setIsSigningin] = useState(false);
    const [isTosOpen, setIsTosOpen] = useState(false);

    return (
        <div className={c.root}>
            {firebaseUser ? (
                <div className={c.continueBox}>
                    <Button onClick={() => setIsSigingup(true)} role={'submit'}>
                        {txt({ en: 'Continue to sign up', ja: 'アカウントを作成して参加' })}
                    </Button>
                </div>
            ) : (
                <>
                    {isSigningin ? (
                        <div className={c.firebaseAuthBox}>
                            <FirebaseAuth />
                            <Button className={c.tosButton} onClick={() => setIsTosOpen(true)}>
                                {txt({ en: 'Terms of service', ja: '利用規約' })}
                            </Button>
                            <TosModal isOpen={isTosOpen} onClose={() => setIsTosOpen(false)} />
                        </div>
                    ) : (
                        <>
                            <MuiTypography className={c.title}>
                                {txt({ en: 'Do you have a Uodka account?', ja: 'Uodkaアカウントを持っていますか？' })}
                            </MuiTypography>
                            <div className={c.buttons}>
                                <Button className={c.cardButton} onClick={() => setIsSigningin(true)}>
                                    <MuiTypography className={c.buttonTitle}>
                                        {txt({ en: 'Yes', ja: '持っている' })}
                                    </MuiTypography>
                                    <MuiTypography className={c.buttonDescription}>
                                        {txt({
                                            en: 'Sign in and join the business above.',
                                            ja: 'ログインし、上のビジネスに参加します。',
                                        })}
                                    </MuiTypography>
                                </Button>
                                <Button className={c.cardButton} onClick={() => setIsSigingup(true)}>
                                    <MuiTypography className={c.buttonTitle}>
                                        {txt({ en: 'No', ja: '持っていない' })}
                                    </MuiTypography>
                                    <MuiTypography className={c.buttonDescription}>
                                        {txt({
                                            en: 'Create a new Uodka account.',
                                            ja: '新しくUodkaアカウントを作成します。',
                                        })}
                                    </MuiTypography>
                                </Button>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
SigninTemplate.displayName = 'SigninTemplate';
