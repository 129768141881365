import { FC, useState, useEffect } from 'react';

import { MutationValidationResult } from '@/utils/UodkaClients/Core';
import { useUserCtx } from '@/utils/ctxs';
import { useLanguage } from '@/utils/customHooks';
import FirebaseAuth from '@/components/0_atom/FirebaseAuth';
import FetchingText from '@/components/0_atom/FetchingText';
import Button from '@/components/0_atom/Button';
import { TosModal } from '@/components/2_org/TosModal';

import { Stepper } from '@/components/3_template/SignupTemplate/Stepper';
import { TosButton } from '@/components/3_template/SignupTemplate/TosButton';
import { Welcome } from '@/components/3_template/SignupTemplate/Welcome';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiFade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 312,
    },
    authBox: {
        marginTop: 14,
    },
    tosButtonBox: {
        marginTop: 14,
        textAlign: 'right',
    },
    tosButton: {
        padding: 6,
        minWidth: 0,
        fontWeight: 600,
        color: theme.palette.text.disabled,
        fontSize: theme.typography.caption.fontSize,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
    },
}));

export const SignupTemplateForMember: FC<{
    runSignup: (input: { idToken: string }) => Promise<MutationValidationResult>;
}> = ({ runSignup }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { firebaseUser } = useUserCtx();
    const [currentStep, setCurrentStep] = useState(0);
    const [isIn, setIsIn] = useState(false);
    const { txt } = useLanguage();
    const [isTosOpen, setIsTosOpen] = useState(false);

    // USEEFFECT
    useEffect(() => {
        if (currentStep === 2 && firebaseUser) {
            runSignup({ idToken: firebaseUser.idToken }).then(() => setCurrentStep(3));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);
    useEffect(() => {
        setTimeout(() => {
            if (!isIn) {
                setIsIn(true);
            }
        }, 250);
        if (firebaseUser) {
            setCurrentStep(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MuiFade in={isIn} timeout={300}>
            <div className={c.root}>
                {currentStep !== 3 ? (
                    <>
                        <MuiTypography variant={'h1'}>
                            {txt({ en: 'Sign up with Uodka', ja: 'Uodkaの利用を開始する' })}
                        </MuiTypography>
                        <Stepper
                            currentStep={currentStep}
                            steps={[
                                {
                                    title: txt({
                                        en: 'Authenticate with your Google Account',
                                        ja: 'Googleアカウントで認証',
                                    }),
                                    children: (
                                        <>
                                            <MuiTypography className={c.description}>
                                                {txt({
                                                    en: 'Uodka uses your Google account for login.',
                                                    ja: 'UodkaではログインにGoogleアカウントを利用します。',
                                                })}
                                            </MuiTypography>
                                            <div className={c.authBox}>
                                                <FirebaseAuth
                                                    onAuthed={() => {
                                                        setCurrentStep(1);
                                                    }}
                                                />
                                                <div className={c.tosButtonBox}>
                                                    <Button className={c.tosButton} onClick={() => setIsTosOpen(true)}>
                                                        {txt({ en: 'Show terms of service', ja: '利用規約を確認' })}
                                                    </Button>
                                                </div>
                                            </div>
                                            <TosModal isOpen={isTosOpen} onClose={() => setIsTosOpen(false)} />
                                        </>
                                    ),
                                },
                                {
                                    title: txt({ en: 'Agree to the terms of service', ja: '利用規約に同意' }),
                                    children: <TosButton onAgree={() => setCurrentStep(2)} />,
                                },
                            ]}
                        />
                        {currentStep === 2 && (
                            <FetchingText text={txt({ en: 'Preparing an account', ja: 'アカウントを準備中' })} />
                        )}
                    </>
                ) : (
                    <Welcome />
                )}
            </div>
        </MuiFade>
    );
};
SignupTemplateForMember.displayName = 'SignupTemplateForMember';
