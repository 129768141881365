/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

export const useFormField = <T>(initialValue: T) => {
    // HOOKS
    const [value, setValue] = useState(initialValue);

    // HANDLER
    const handleSetValue = (newValue: T) => {
        if (newValue !== value) {
            setValue(newValue);
        }
    };

    // USEEFFECT
    useEffect(() => {
        handleSetValue(initialValue);
    }, [initialValue]);

    return [value, handleSetValue] as const;
};
