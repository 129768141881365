import { useEffect } from 'react';

import { useLanguageCtx } from '@/utils/ctxs';

export const useLanguage = () => {
    // HOOKS
    const { language } = useLanguageCtx();

    // DATA
    const txt = (multilingualTexts: { en: string; ja?: string }) => {
        return multilingualTexts[language || 'en'] || multilingualTexts.en;
    };
    const getDateStr = (date: Date) => {
        const monthNumber = date.getMonth();
        const dateNumber = date.getDate();
        const yearNumber = date.getFullYear();
        if (language === 'ja') {
            return `${String(yearNumber)}/${String(monthNumber + 1)}/${String(dateNumber)}`;
        }
        const month = (() => {
            if (monthNumber === 0) {
                return 'Jan.';
            }
            if (monthNumber === 1) {
                return 'Feb.';
            }
            if (monthNumber === 2) {
                return 'Mar.';
            }
            if (monthNumber === 3) {
                return 'Apr.';
            }
            if (monthNumber === 4) {
                return 'May.';
            }
            if (monthNumber === 5) {
                return 'Jun.';
            }
            if (monthNumber === 6) {
                return 'Jul.';
            }
            if (monthNumber === 7) {
                return 'Aug.';
            }
            if (monthNumber === 8) {
                return 'Sep.';
            }
            if (monthNumber === 9) {
                return 'Oct.';
            }
            if (monthNumber === 10) {
                return 'Nov.';
            }
            return 'dec.';
        })();
        return `${month} ${String(dateNumber)}, ${String(yearNumber)}`;
    };
    const getDateTimeStr = (date: Date) => {
        const hours = String(date.getHours());
        const minutes = (() => {
            const num = date.getMinutes();
            if (num < 10) {
                return `0${String(num)}`;
            }
            return String(num);
        })();
        const today = new Date();
        const termDays = (() => {
            const dateDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            return Math.floor((todayDate.getTime() - dateDate.getTime()) / 86400000);
        })();
        if (termDays === 0) {
            return `${txt({ en: 'Today,', ja: '今日' })} ${hours}:${minutes}`;
        }
        if (termDays === 1) {
            return `${txt({ en: 'Yesterday,', ja: '昨日' })} ${hours}:${minutes}`;
        }
        return `${getDateStr(date)} ${hours}:${minutes}`;
    };

    // USEEFFECT
    useEffect(() => {
        console.log(' language->', language);
    }, [language]);

    return {
        language,
        txt,
        getDateStr,
        getDateTimeStr,
    };
};
