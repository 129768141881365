import { FC, ReactNode } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiStepper from '@material-ui/core/Stepper';
import MuiStep from '@material-ui/core/Step';
import MuiStepLabel from '@material-ui/core/StepLabel';
import MuiStepContent from '@material-ui/core/StepContent';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        marginTop: 49,
        '& .MuiStepConnector-line': {
            border: 'none',
            display: 'none',
        },
    },
    step: {},
    label: {
        fontWeight: 600,
        marginBottom: 22,
        '& .MuiStepIcon-text': {
            fontWeight: 600,
        },
        '& .MuiStepLabel-label': {
            fontWeight: 600,
            fontSize: theme.typography.body1.fontSize,
        },
        '& .MuiStepLabel-active': {
            color: theme.palette.primary.main,
        },
    },
    content: {
        border: 'none',
        marginTop: 0,
        marginBottom: 26,
    },
}));

export type StepProps = {
    title: string;
    children: ReactNode;
};

export const Stepper: FC<{
    steps: StepProps[];
    currentStep: number;
}> = ({ steps, currentStep }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <MuiStepper className={c.root} activeStep={currentStep} orientation={'vertical'}>
            {steps.map((step, i) => (
                <MuiStep key={i} className={c.step}>
                    <MuiStepLabel className={c.label}>{step.title}</MuiStepLabel>
                    <MuiStepContent className={c.content}>{step.children}</MuiStepContent>
                </MuiStep>
            ))}
        </MuiStepper>
    );
};
Stepper.displayName = 'Stepper';
