import { FC } from 'react';

import { useLanguage } from '@/utils/customHooks';
import { getEnvVariables } from '@/utils/envVariables';
import Button from '@/components/0_atom/Button';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';

import { MemberWithContact } from '@/components/3_template/InviteForContactTemplate/useBusinessWithContacts';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    businessSelectorBox: {
        textAlign: 'center',
    },
    submitButtonBox: {
        textAlign: 'right',
        width: '100%',
        marginTop: 53,
    },
    submitButton: {
        height: 41,
        padding: '6px 24px',
    },
}));

export const Linked: FC<{
    memberWithContact: MemberWithContact;
}> = ({ memberWithContact }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    // USEEFFECT

    return (
        <>
            <div className={c.businessSelectorBox}>
                <ContactNameWithIcon
                    contact={{
                        name: memberWithContact.member.business.name,
                    }}
                    profile={memberWithContact.profile}
                />
            </div>
            <div className={c.submitButtonBox}>
                <Button className={c.submitButton} role={'submit'} href={getEnvVariables().consoleRootUrl}>
                    {txt({
                        en: 'Open Console',
                        ja: 'コンソールへ',
                    })}
                </Button>
            </div>
        </>
    );
};
Linked.displayName = 'Linked';
