import { FC } from 'react';
import clsx from 'clsx';

import { ContactItem, useLanguage } from '@/utils/customHooks';
import { ButtonWithMenu } from '@/components/1_mol/ButtonWithMenu';
import ContactNameWithIcon from '@/components/2_org/ContactNameWithIcon';

import SvgChevronUp from '@/svgs/fa-chevron-up-solid';
import SvgPlus from '@/svgs/fa-plus-regular';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 'solid 2px rgba(0,0,0,.05)',
        position: 'relative',
        background: '#fff',
        // padding: '21px 17px',
        paddingRight: 48,
        minWidth: 237,
        minHeight: 54,
        justifyContent: 'flex-start',
        '& .MuiButton-endIcon': {
            position: 'absolute',
            right: 14,
            width: 12,
            height: 'auto',
            transform: 'rotate(180deg)',
            color: theme.palette.text.disabled,
        },
        '& .MuiButton-label': {
            fontWeight: 600,
        },
    },
    menuPaper: {
        minWidth: 237,
    },
    newContact: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& svg': {
            width: 14,
            height: 14,
            margin: '7px 18px 7px 7px',
        },
        '& span': {
            fontSize: theme.typography.body2.fontSize,
            fontWeight: 600,
            lineHeight: '1',
        },
    },
}));

const NewContact: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();

    return (
        <div className={c.newContact}>
            <SvgPlus />
            <span>{txt({ en: 'Add new contact', ja: '新しく追加' })}</span>
        </div>
    );
};

export const ContactSelector: FC<{
    className?: string;
    contactItems: ContactItem[];
    selectedContactItem: ContactItem | undefined;
    setSelectedContactItem: (contactItem: ContactItem | undefined) => void;
}> = ({ className, contactItems, selectedContactItem, setSelectedContactItem }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <ButtonWithMenu
            menuEmptyPlaceholder={'unexpected'}
            className={clsx(c.root, className)}
            items={[
                ...contactItems.map((contactItem) => ({
                    children: <ContactNameWithIcon contact={contactItem.contact} profile={contactItem.profile?.data} />,
                    onClick: () => setSelectedContactItem(contactItem),
                    isDisabled: contactItem.id === selectedContactItem?.id,
                })),
                {
                    children: <NewContact />,
                    onClick: () => setSelectedContactItem(undefined),
                    isDisabled: false,
                },
            ]}
            endIcon={<SvgChevronUp />}
            menuPaperClassName={c.menuPaper}
        >
            {selectedContactItem ? (
                <ContactNameWithIcon
                    contact={selectedContactItem.contact}
                    profile={selectedContactItem.profile?.data}
                />
            ) : (
                <NewContact />
            )}
        </ButtonWithMenu>
    );
};
ContactSelector.displayName = 'ContactSelector';
