import { FC, ReactNode, useEffect } from 'react';

import { useErrorCtx, InviteForContactCtx } from '@/utils/ctxs';
import { useInviteForContact, useBusinessProfile } from '@/utils/customHooks';

const InviteForContactProvider: FC<{
    issuerBusinessId: string;
    issuerContactId: string;
    inviteCodeText: string;
    children: ReactNode;
}> = ({ issuerBusinessId, issuerContactId, inviteCodeText, children }) => {
    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { inviteForContact } = useInviteForContact({ issuerBusinessId, issuerContactId, inviteCodeText });
    const { profile } = useBusinessProfile({ businessId: issuerBusinessId });

    // USEEFFECT
    useEffect(() => {
        if (!inviteForContact) {
            setIs404(true);
        }
    }, [setIs404, inviteForContact]);

    return (
        <InviteForContactCtx.Provider value={{ inviteForContact, issuerProfile: profile }}>
            {children}
        </InviteForContactCtx.Provider>
    );
};
InviteForContactProvider.displayName = 'InviteForContactProvider';
export default InviteForContactProvider;
