import { FC, useState, useEffect } from 'react';

import { initUodkaCoreFirebaseUserClient } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import { useInviteForMemberCtxAbsolutely, useUserCtx } from '@/utils/ctxs';
import { getEnvVariables } from '@/utils/envVariables';
import { getFirebaseAuth } from '@/utils/GoogleCloud/firebase';
import Button from '@/components/0_atom/Button';
import MutationButton from '@/components/0_atom/MutationButton';
import { BusinessWithLogo } from '@/components/2_org/BusinessWithLogo';
import { SigninTemplate } from '@/components/3_template/SigninTemplate';
import { SignupTemplateForMember } from '@/components/3_template/SignupTemplate';

import SvgCheck from '@/svgs/fa-check-light';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {},
    already: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.text.disabled,
        marginTop: 44,
        textAlign: 'center',
        marginBottom: 24,
    },
    joinButtonBox: {
        textAlign: 'center',
        marginTop: 31,
        marginBottom: 74,
    },
    joinButton: {
        padding: '6px 32px',
    },
    joinButtonJoined: {
        padding: '6px 32px',
        color: `${theme.palette.primary.main} !important`,
        background: 'none !important',
    },
    checkIcon: {
        color: `${theme.palette.primary.main} !important`,
    },
    goToConsoleButton: {
        position: 'absolute',
        bottom: 26,
        right: 27,
        // padding: '6px 24px',
    },
}));

export const InviteForMemberTemplateContent: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const { inviteForMember, issuerProfile } = useInviteForMemberCtxAbsolutely();
    const [isSigingup, setIsSigingup] = useState(false);
    const [isJoined, setIsJoined] = useState(false);
    const { memberWithBusinessProfiles, userClient, firebaseUser } = useUserCtx();

    // HANDLER
    const handleRunJoin = async ({ idToken }: { idToken: string }) => {
        const client = initUodkaCoreFirebaseUserClient({ firebaseUserIdToken: idToken });
        const { result } = await client.applyInviteForBusinessMember({
            businessId: inviteForMember.business.id,
            inviteCodeText: inviteForMember.codeText,
        });
        setIsJoined(true);
        return result;
    };

    // USEEFFECT
    useEffect(() => {
        const auth = getFirebaseAuth();
        auth.getRedirectResult().then((result) => {
            if (result.user && !userClient) {
                setIsSigingup(true);
            }
            console.log('result->', result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !isSigingup ? (
        <div className={c.root}>
            <MuiTypography variant={'h1'} className={c.title} align={'center'}>
                {txt({
                    en: `${inviteForMember.business.name} invites you as a member`,
                    ja: 'メンバーとして招待されています',
                })}
            </MuiTypography>
            <BusinessWithLogo name={inviteForMember.business.name} logoSrc={issuerProfile?.logoSrc} />
            <div>
                {memberWithBusinessProfiles?.find(
                    ({ member }) => member.business.id === inviteForMember.business.id
                ) ? (
                    <MuiTypography className={c.already}>
                        {txt({
                            en: 'You are already a member',
                            ja: '既にメンバーです',
                        })}
                    </MuiTypography>
                ) : userClient && firebaseUser ? (
                    <>
                        <div className={c.joinButtonBox}>
                            <MutationButton
                                className={isJoined ? c.joinButtonJoined : c.joinButton}
                                mutation={{
                                    run: () => handleRunJoin({ idToken: firebaseUser.idToken }),
                                    description: txt({
                                        en: `Join ${inviteForMember.business.name} as a member`,
                                        ja: `${inviteForMember.business.name}にメンバーとして参加`,
                                    }),
                                }}
                                role={'submit'}
                                disabled={isJoined}
                                startIcon={isJoined ? <SvgCheck className={c.checkIcon} /> : undefined}
                            >
                                {isJoined
                                    ? txt({
                                          en: 'Joined',
                                          ja: '参加済み',
                                      })
                                    : txt({
                                          en: 'Join',
                                          ja: '参加する',
                                      })}
                            </MutationButton>
                        </div>
                        {isJoined && (
                            <Button
                                className={c.goToConsoleButton}
                                role={'submit'}
                                href={getEnvVariables().consoleRootUrl}
                            >
                                {txt({
                                    en: 'Open Console',
                                    ja: 'コンソールへ',
                                })}
                            </Button>
                        )}
                    </>
                ) : (
                    <SigninTemplate setIsSigingup={setIsSigingup} />
                )}
            </div>
        </div>
    ) : (
        <SignupTemplateForMember runSignup={handleRunJoin} />
    );
};
InviteForMemberTemplateContent.displayName = 'InviteForMemberTemplateContent';
