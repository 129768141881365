import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        margin: '73px auto 0 auto',
    },
    inner: {
        textAlign: 'center',
    },
    img: {
        width: 'auto',
        height: 27,
        maxWidth: 200,
        objectFit: 'contain',
        verticalAlign: 'top',
        display: 'inline-block',
        marginBottom: 14,
    },
    name: {
        color: theme.palette.primary.main,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 600,
    },
}));

export const BusinessWithLogo: FC<{
    className?: string;
    name: string;
    logoSrc?: string;
}> = ({ className, name, logoSrc }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <div className={c.inner}>
                {logoSrc && <img className={c.img} src={logoSrc} alt={name} />}
                <div className={c.name}>{name}</div>
            </div>
        </div>
    );
};
BusinessWithLogo.displayName = 'BusinessWithLogo';
