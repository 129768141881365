import { useState, useEffect, useCallback } from 'react';

import { initUodkaCorePublicClient, InviteForContact } from '@/utils/UodkaClients/Core';

export const useInviteForContact = ({
    issuerBusinessId,
    issuerContactId,
    inviteCodeText,
}: {
    issuerBusinessId: string;
    issuerContactId: string;
    inviteCodeText: string;
}) => {
    // HOOKS
    const [invite, setInvite] = useState<InviteForContact | undefined | 'fetching'>('fetching');
    const fetchInvite = useCallback(async () => {
        const client = initUodkaCorePublicClient();
        const fetchedInvite = await client.getInviteForContact({ issuerBusinessId, issuerContactId, inviteCodeText });
        setInvite(fetchedInvite);
    }, [issuerBusinessId, issuerContactId, inviteCodeText]);

    // USEEFFECT
    useEffect(() => {
        fetchInvite();
    }, [fetchInvite]);

    return {
        inviteForContact: invite,
    };
};
