import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

import HealthProvider from '@/providers/HealthProvider';
import MutationsProvider from '@/providers/MutationsProvider';
import ErrorProvider from '@/providers/ErrorProvider';
import UserProvider from '@/providers/UserProvider';
import InviteForContactProvider from '@/providers/InviteForContactProvider';
import InviteForMemberProvider from '@/providers/InviteForMemberProvider';
import NetworkIndicator from '@/components/2_org/NetworkIndicator';
import { GlobalTemplate } from '@/components/3_template/GlobalTemplate';
import FetchingOrErrorTemplate from '@/components/3_template/FetchingOrErrorTemplate';
import { InviteForContactTemplate } from '@/components/3_template/InviteForContactTemplate';
import { InviteForMemberTemplate } from '@/components/3_template/InviteForMemberTemplate';
import { useUserCtx } from '@/utils/ctxs';
import { useRouting } from '@/utils/customHooks';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import muiTheme from '@/styles/muiTheme';

const Handler: FC = () => {
    // HOOKS
    const routing = useRouting();
    const { isFetching } = useUserCtx();
    console.log('routing->', routing);
    return (
        <GlobalTemplate>
            {isFetching ? (
                <FetchingOrErrorTemplate />
            ) : routing ? (
                routing.slug1 === 'member' ? (
                    <InviteForMemberProvider businessId={routing.slug2} inviteCodeText={routing.slug3}>
                        <InviteForMemberTemplate />
                    </InviteForMemberProvider>
                ) : routing.slug1 === 'contact' && routing.slug4 ? (
                    <InviteForContactProvider
                        issuerBusinessId={routing.slug2}
                        issuerContactId={routing.slug3}
                        inviteCodeText={routing.slug4}
                    >
                        <InviteForContactTemplate />
                    </InviteForContactProvider>
                ) : (
                    <FetchingOrErrorTemplate />
                )
            ) : (
                <FetchingOrErrorTemplate />
            )}
        </GlobalTemplate>
    );
};

const AppRoot: FC = () => {
    return (
        <ErrorProvider>
            <HealthProvider>
                <MutationsProvider>
                    <UserProvider>
                        <BrowserRouter>
                            <ThemeProvider theme={muiTheme}>
                                <CssBaseline />
                                <NetworkIndicator />
                                <Handler />
                            </ThemeProvider>
                        </BrowserRouter>
                    </UserProvider>
                </MutationsProvider>
            </HealthProvider>
        </ErrorProvider>
    );
};
AppRoot.displayName = 'AppRoot';
export default AppRoot;
