import { FC, ReactNode, useState, useEffect } from 'react';

import { LanguageCtx, useUserCtx } from '@/utils/ctxs';

const LanguageProvider: FC<{
    inviteLanguage: 'en' | 'ja' | undefined;
    children: ReactNode;
}> = ({ inviteLanguage, children }) => {
    // HOOKS
    const { userClient } = useUserCtx();
    const [language, setLanguage] = useState<'en' | 'ja' | undefined>(userClient?.language || inviteLanguage);

    // USEEFFECT
    useEffect(() => {
        const newLanguage = userClient?.language || inviteLanguage;
        if (newLanguage !== language) {
            setLanguage(newLanguage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userClient?.language, inviteLanguage]);

    return <LanguageCtx.Provider value={{ language, setLanguage }}>{children}</LanguageCtx.Provider>;
};
LanguageProvider.displayName = 'LanguageProvider';
export default LanguageProvider;
