import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { getEnvVariables } from '@/utils/envVariables';

export const getFirebaseCore = () => {
    const existingFirebasCoreApp = firebase.apps.find((app) => app.name === 'core');
    if (existingFirebasCoreApp) {
        return existingFirebasCoreApp;
    }
    const { apiKey, authDomain, projectId } = getEnvVariables().firebase.core;
    return firebase.initializeApp(
        {
            apiKey,
            authDomain,
            projectId,
        },
        'core'
    );
};

export const getFirebaseProfile = () => {
    const existingFirebasProfileApp = firebase.apps.find((app) => app.name === 'profile');
    if (existingFirebasProfileApp) {
        return existingFirebasProfileApp;
    }
    const { storageBucket, projectId } = getEnvVariables().firebase.profile;
    return firebase.initializeApp(
        {
            projectId,
            storageBucket,
        },
        'profile'
    );
};

export const getFirebaseStorage = () => {
    const app = getFirebaseProfile();
    return firebase.storage(app);
};

export const getFirebaseAuth = (language?: 'en' | 'ja') => {
    const app = getFirebaseCore();
    const auth = firebase.auth(app);
    auth.languageCode = language || 'en';
    return auth;
};

export const handleStartAuth = ({ redirectUrl, language }: { redirectUrl?: string; language?: 'en' | 'ja' }) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
        languageCode: language || 'en',
        hl:language || 'en',
        // redirect_uri: getEnvVariables().rootUrl + (redirectUrl || ''),
        // state: 'abcdefg'
    });
    const auth = getFirebaseAuth(language);
    auth.signInWithRedirect(provider);
};