import { FC, useState, useEffect, useCallback } from 'react';

import { initUodkaCorePublicClient } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import Modal from '@/components/0_atom/Modal';
import FetchingText from '@/components/0_atom/FetchingText';
import MarkdownViewer from '@/components/0_atom/MarkdownViewer';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '10px 29px',
    },
    paper: {
        position: 'relative',
    },
    tosHeading: {},
    tosText: {
        lineHeight: '1.5',
        color: 'rgba(0,0,0,.65)',
        '& h1': {
            color: theme.palette.primary.main,
            fontSize: theme.typography.h1.fontSize,
            fontWeight: 600,
        },
        '& h2': {
            color: theme.palette.primary.main,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 600,
        },
        '& ol': {
            paddingLeft: '1em',
        },
    },
    actions: {
        position: 'sticky',
        bottom: -22,
        left: -27,
        background: '#fff',
        padding: '7px 0 22px 0',
        textAlign: 'right',
    },
    agreeButton: {
        minWidth: '30%',
    },
}));

export const TosButton: FC<{
    onAgree: () => void;
}> = ({ onAgree }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [isOpen, setIsOpen] = useState(false);
    const [tos, setTos] = useState<string | undefined>(undefined);

    // CALLBACK
    const fetchTos = useCallback(async () => {
        const client = initUodkaCorePublicClient();
        const { tos: fetchedTos } = await client.getLegalTexts();
        setTos(fetchedTos);
    }, []);

    // USEEFFECT
    useEffect(() => {
        fetchTos();
    }, [fetchTos]);

    return (
        <>
            <Button className={c.button} onClick={() => setIsOpen(true)} role={'submit'}>
                {txt({ en: 'Open terms of service', ja: '利用規約を開く' })}
            </Button>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} animation={'up'} paperClassName={c.paper}>
                {/* <MuiTypography variant={'h1'} className={c.tosHeading}>
                    {txt({ en: 'Terms of service', ja: '利用規約' })}
                </MuiTypography> */}
                <div className={c.tosText}>{tos ? <MarkdownViewer textMd={tos} /> : <FetchingText />}</div>
                <div className={c.actions}>
                    <Button
                        className={c.agreeButton}
                        onClick={() => {
                            setIsOpen(false);
                            onAgree();
                        }}
                        role={'submit'}
                    >
                        {txt({ en: 'Agree', ja: '同意する' })}
                    </Button>
                </div>
            </Modal>
        </>
    );
};
TosButton.displayName = 'TosButton';
