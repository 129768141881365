import { createContext, useContext } from 'react';

type LanguageCtxType = {
    language: 'en' | 'ja' | undefined;
    setLanguage: (language: 'en' | 'ja' | undefined) => void;
};
export const LanguageCtx = createContext<LanguageCtxType>({
    language: undefined,
    setLanguage: () => undefined,
});
export const useLanguageCtx = () => {
    return useContext(LanguageCtx);
};
