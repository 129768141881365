import { useState, useEffect, useCallback } from 'react';

import { initUodkaProfileClientForAnonymous, BusinessProfile } from '@/utils/UodkaClients/Profile';

export const getBusinessProfile = async (clientId: string): Promise<BusinessProfile | undefined> => {
    const client = initUodkaProfileClientForAnonymous();
    return client.getProfileData({ businessId: clientId });
};

export const useBusinessProfile = ({ businessId }: { businessId?: string }) => {
    // HOOKS
    const [fetchedProfile, setFetchedProfile] = useState<BusinessProfile | undefined | 'fetching'>(
        businessId ? 'fetching' : undefined
    );
    const fetchBusinessProfile = useCallback(async () => {
        if (businessId) {
            const businessProfile = await getBusinessProfile(businessId);
            setFetchedProfile(businessProfile);
        } else {
            setFetchedProfile(undefined);
        }
    }, [businessId]);

    // USEEFFECT
    useEffect(() => {
        fetchBusinessProfile();
    }, [fetchBusinessProfile]);

    return {
        profile: fetchedProfile,
    };
};
