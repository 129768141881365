import { useLocation } from 'react-router-dom';

import { useErrorCtx } from '@/utils/ctxs';

type Routing =
    | {
          slug1: 'member';
          slug2: string;
          slug3: string;
      }
    | {
          slug1: 'contact';
          slug2: string;
          slug3: string;
          slug4: string;
      };

export const useRouting = () => {
    // HOOKS
    const { pathname } = useLocation();
    const { setIs404 } = useErrorCtx();

    const routing: Routing | undefined = (() => {
        const matchArr = pathname.match(/^\/([^/?#]+)\/([^/?#]+)\/([^/?#]+)\/?([^/?#]+)?/);
        if (matchArr) {
            const [_, slug1, slug2, slug3, slug4]: (string | undefined)[] = matchArr;
            const validSlug1 = (() => {
                if (slug1) {
                    if (['member', 'contact'].includes(slug1)) {
                        return slug1 as 'member' | 'contact';
                    }
                }
                return undefined;
            })();
            if (validSlug1 === 'member' && slug2 && slug3) {
                return {
                    slug1: validSlug1,
                    slug2,
                    slug3,
                };
            }
            if (validSlug1 === 'contact' && slug2 && slug3 && slug4) {
                return {
                    slug1: validSlug1,
                    slug2,
                    slug3,
                    slug4,
                };
            }
        }
        setIs404(true);
        return undefined;
    })();

    return routing;
};
