import { FC } from 'react';

import { useUserCtx, useErrorCtx } from '@/utils/ctxs';
import ErrorDisplay from '@/components/2_org/ErrorDisplay';
import FetchingText from '@/components/0_atom/FetchingText';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

const FetchingOrErrorTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { is404, is500 } = useErrorCtx();
    const { isFetching } = useUserCtx();
    console.log('{ isFetching,is404, is500 }->', { isFetching, is404, is500 });
    return (
        <div className={c.root}>
            {isFetching ? (
                <FetchingText />
            ) : is500 ? (
                <ErrorDisplay errorCode={500} />
            ) : is404 ? (
                <ErrorDisplay errorCode={404} />
            ) : (
                <ErrorDisplay errorCode={500} />
            )}
        </div>
    );
};
FetchingOrErrorTemplate.displayName = 'FetchingOrErrorTemplate';
export default FetchingOrErrorTemplate;
