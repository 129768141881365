import dotenv from 'dotenv';

dotenv.config();

type UodkaEnv = 'development' | 'staging' | 'production';

const getUodkaEnv = (): UodkaEnv => {
    const str = process.env.REACT_APP_UODKA_ENV;
    if (str === 'production') {
        return 'production';
    }
    if (str === 'staging') {
        return 'staging';
    }
    if (str === 'development') {
        return 'development';
    }
    throw new Error(`getUodkaEnv: Unexpected process.env.REACT_APP_UODKA_ENV got->${str}`);
};

interface EnvVariables {
    uodkaEnv: UodkaEnv;
    rootUrl: string;
    consoleRootUrl: string;
    service: {
        core: {
            graphqlEndpointUrl: string;
        };
        profile: {
            graphqlEndpointUrl: string;
        };
    };
    firebase: {
        core: {
            projectId: string;
            authDomain: string;
            apiKey: string;
        };
        profile: {
            projectId: string;
            storageBucket: string;
        };
    };
}

export const getEnvVariables = (): EnvVariables => {
    return {
        uodkaEnv: getUodkaEnv(),
        rootUrl: process.env.REACT_APP_ROOT_URL || '',
        consoleRootUrl: process.env.REACT_APP_CONSOLE_ROOT_URL || '',
        service: {
            core: {
                graphqlEndpointUrl: process.env.REACT_APP_SERVICE_CORE_GRAPHQL_ENDPOINT_URL || '',
            },
            profile: {
                graphqlEndpointUrl: process.env.REACT_APP_SERVICE_PROFILE_GRAPHQL_ENDPOINT_URL || '',
            },
        },
        firebase: {
            core: {
                projectId: process.env.REACT_APP_FIREBASE_CORE_PROJECT_ID || '',
                authDomain: process.env.REACT_APP_FIREBASE_CORE_AUTH_DOMAIN || '',
                apiKey: process.env.REACT_APP_FIREBASE_CORE_API_KEY || '',
            },
            profile: {
                projectId: process.env.REACT_APP_FIREBASE_PROFILE_PROJECT_ID || '',
                storageBucket: process.env.REACT_APP_FIREBASE_PROFILE_STORAGE_BUCKET || '',
            },
        },
    };
};
