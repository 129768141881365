import { FC, ReactNode, useEffect } from 'react';

import { useErrorCtx, InviteForMemberCtx } from '@/utils/ctxs';
import { useInviteForMember, useBusinessProfile } from '@/utils/customHooks';

const InviteForMemberProvider: FC<{
    businessId: string;
    inviteCodeText: string;
    children: ReactNode;
}> = ({ businessId, inviteCodeText, children }) => {
    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { inviteForMember } = useInviteForMember({ businessId, inviteCodeText });
    const { profile } = useBusinessProfile({ businessId });

    // USEEFFECT
    useEffect(() => {
        if (!inviteForMember) {
            setIs404(true);
        }
    }, [setIs404, inviteForMember]);

    return (
        <InviteForMemberCtx.Provider value={{ inviteForMember, issuerProfile: profile }}>
            {children}
        </InviteForMemberCtx.Provider>
    );
};
InviteForMemberProvider.displayName = 'InviteForMemberProvider';
export default InviteForMemberProvider;
