import { MutationValidationResult } from '@/utils/UodkaClients/Core/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/UodkaClients/Core/graphql/graphqlSdk';
import {
    UodkaCoreClientForOwner,
    UodkaCoreClientForGeneral,
    UodkaCoreClientForObserver,
} from '@/utils/UodkaClients/Core/clients';

interface ApplyInviteForBusinessMemberInput {
    businessId: string;
    inviteCodeText: string;
}
interface ApplyInviteForContactWithSigningUpInput {
    requestBusinessName: string;
    requestBusinessRemitterNameKana: string;
    issuerBusinessId: string;
    issuerContactId: string;
    inviteCodeText: string;
}

interface UodkaCoreFirebaseUserClientInterface {
    applyInviteForBusinessMember: (
        input: ApplyInviteForBusinessMemberInput
    ) => Promise<{
        coreClient: UodkaCoreClientForOwner | UodkaCoreClientForGeneral | UodkaCoreClientForObserver;
        result: MutationValidationResult;
    }>;
    applyInviteForContactWithSigningUp: (
        input: ApplyInviteForContactWithSigningUpInput
    ) => Promise<{
        coreClient: UodkaCoreClientForOwner;
        result: MutationValidationResult;
    }>;
}

interface ConstructorInput {
    graphqlSdk: GraphQLSdk;
}

export class UodkaCoreFirebaseUserClient implements UodkaCoreFirebaseUserClientInterface {
    protected _graphqlSdk: GraphQLSdk;

    constructor({ graphqlSdk }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
    }

    async applyInviteForBusinessMember(input: ApplyInviteForBusinessMemberInput) {
        const { applyInviteForBusinessMember } = await this._graphqlSdk.applyInviteForBusinessMember({ input });
        const { business, businessMember, result } = applyInviteForBusinessMember;
        const { role } = businessMember;
        const coreClient = (() => {
            if (role === 'owner') {
                return new UodkaCoreClientForOwner({ graphqlSdk: this._graphqlSdk, id: business.id });
            }
            if (role === 'general') {
                return new UodkaCoreClientForGeneral({ graphqlSdk: this._graphqlSdk, id: business.id });
            }
            return new UodkaCoreClientForObserver({ graphqlSdk: this._graphqlSdk, id: business.id });
        })();
        return {
            coreClient,
            result,
        };
    }

    async applyInviteForContactWithSigningUp(input: ApplyInviteForContactWithSigningUpInput) {
        const { applyInviteForContactWithSigningUp } = await this._graphqlSdk.applyInviteForContactWithSigningUp({
            input,
        });
        const { business, result } = applyInviteForContactWithSigningUp;
        return {
            coreClient: new UodkaCoreClientForOwner({ graphqlSdk: this._graphqlSdk, id: business.id }),
            result,
        };
    }
}
