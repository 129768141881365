import { FC, ReactNode, useState, useEffect, useCallback } from 'react';

import { initUodkaUserClientWithMembers, UodkaUserClient, MemberInUser } from '@/utils/UodkaClients/Core';
import { UserCtx, MemberInUserWithBusinessProfile, useHealthCtx } from '@/utils/ctxs';
import { useFirebaseUser, getBusinessProfile } from '@/utils/customHooks';

// initializeをuseEffect内とreturnに使いたいので、useCallbackを使用。
// https://ja.reactjs.org/docs/hooks-faq.html

const getMembersWithBusinessProfile = (members: MemberInUser[]): Promise<MemberInUserWithBusinessProfile[]> => {
    return Promise.all(
        members.map(async (member) => {
            const businessProfile = await getBusinessProfile(member.business.id);
            return {
                member,
                businessProfile,
            };
        })
    );
};

const UserProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { core } = useHealthCtx();
    const { firebaseUser, signOut } = useFirebaseUser();
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isFirebaseError, setIsFirebaseError] = useState<boolean | undefined>(undefined);
    const [userClient, setUserClient] = useState<UodkaUserClient | undefined>(undefined);
    const [memberWithBusinessProfiles, setMemberWithBusinessProfiles] =
        useState<MemberInUserWithBusinessProfile[] | undefined>(undefined);
    const initialize = useCallback(async () => {
        if (firebaseUser.idToken && core.health?.code === 'ok') {
            setIsFetching(true);
            const result = await initUodkaUserClientWithMembers({
                firebaseUserIdToken: firebaseUser.idToken,
            }).catch(() => {
                return undefined;
            });
            if (result) {
                const { userClient, members } = result;
                setUserClient(userClient);
                setMemberWithBusinessProfiles(await getMembersWithBusinessProfile(members));
            } else {
                setUserClient(undefined);
                setMemberWithBusinessProfiles(undefined);
            }
            setIsFetching(false);
        } else {
            setUserClient(undefined);
            setMemberWithBusinessProfiles(undefined);
            setIsFetching(firebaseUser.isFetching);
        }
    }, [firebaseUser, core.health]);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);
    useEffect(() => {
        setIsFirebaseError(firebaseUser.isError);
    }, [firebaseUser.isError]);

    return (
        <UserCtx.Provider
            value={{
                userClient,
                memberWithBusinessProfiles,
                isFetching,
                reInitialize: initialize,
                signOut,
                firebaseUser: (() => {
                    const { uid, email, idToken } = firebaseUser;
                    if (uid && email && idToken) {
                        return {
                            uid,
                            email,
                            idToken,
                        };
                    }
                    return undefined;
                })(),
                isFirebaseError,
            }}
        >
            {children}
        </UserCtx.Provider>
    );
};
UserProvider.displayName = 'UserProvider';
export default UserProvider;
