import { createContext, useContext } from 'react';

import { UodkaCoreServiceHealth } from '@/utils/UodkaClients/Core';
import { UodkaProfileServiceHealth } from '@/utils/UodkaClients/Profile';

export interface ServiceHealthWithFetchStatus<T> {
    health?: T;
    fetchStatus: 'fetchError' | 'fetching' | 'fetched';
}
export type ServiceHealth = UodkaCoreServiceHealth;

interface HealthCtxType {
    core: ServiceHealthWithFetchStatus<UodkaCoreServiceHealth>;
    profile: ServiceHealthWithFetchStatus<UodkaProfileServiceHealth>;
}

export const HealthCtx = createContext<HealthCtxType>({
    core: {
        fetchStatus: 'fetching',
    },
    profile: {
        fetchStatus: 'fetching',
    },
});
export const useHealthCtx = () => {
    return useContext(HealthCtx);
};
