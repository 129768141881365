import { createContext, useContext } from 'react';

import { InviteForContact } from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';

type InviteForContactCtxType = {
    inviteForContact: InviteForContact | undefined | 'fetching';
    issuerProfile: BusinessProfile | undefined | 'fetching';
};
export const InviteForContactCtx = createContext<InviteForContactCtxType>({
    inviteForContact: 'fetching',
    issuerProfile: 'fetching',
});
export const useInviteForContactCtx = () => {
    return useContext(InviteForContactCtx);
};
export const useInviteForContactCtxAbsolutely = () => {
    const { inviteForContact, issuerProfile } = useInviteForContactCtx();
    if (!inviteForContact || inviteForContact === 'fetching' || issuerProfile === 'fetching') {
        throw new Error(
            "useInviteForContactCtxAbsolutely: (!inviteForContact || inviteForContact === 'fetching' || issuerProfile === 'fetching')"
        );
    }
    return { inviteForContact, issuerProfile };
};
