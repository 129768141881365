import { FC } from 'react';
import clsx from 'clsx';

import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import Button from '@/components/0_atom/Button';
import BusinessIcon from '@/components/0_atom/BusinessIcon';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        padding: '7px 9px',
        justifyContent: 'flex-start',
        fontWeight: 600,
        '& .MuiButton-startIcon': {
            width: 28,
            height: 28,
            margin: '0 11px 0 0 ',
        },
        '& .MuiTypography-root': {
            lineHeight: 1,
            fontWeight: 600,
            fontSize: theme.typography.body2.fontSize,
        },
    },
    div: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
            lineHeight: 1,
            fontWeight: 600,
            fontSize: theme.typography.body2.fontSize,
        },
    },
}));

const ContactNameWithIcon: FC<{
    className?: string;
    contact: {
        name: string;
        relatedBusiness?: {
            name: string;
        };
    };
    profile?: BusinessProfile;
    onClick?: () => void;
    isSelected?: boolean;
    isButton?: boolean;
    size?: 'verySmall' | 'large';
}> = ({ className, contact, profile, onClick, isSelected, isButton, size }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const name = (() => {
        const { name, relatedBusiness } = contact;
        return relatedBusiness?.name || name;
    })();
    const divIconStyle = {
        width: size === 'verySmall' ? 14 : size === 'large' ? 38 : 28,
        height: size === 'verySmall' ? 14 : size === 'large' ? 38 : 28,
        marginRight: size === 'verySmall' ? 3 : 10,
    };

    return isButton ? (
        <Button
            className={clsx(c.button, className)}
            onClick={onClick}
            fullWidth
            isSelected={isSelected}
            startIcon={
                <BusinessIcon name={name} iconSrc={profile?.iconSrc} themeColor={profile?.themeColor} size={size} />
            }
        >
            {name}
        </Button>
    ) : (
        <div className={clsx(c.div, className)}>
            <div style={divIconStyle}>
                <BusinessIcon name={name} iconSrc={profile?.iconSrc} themeColor={profile?.themeColor} size={size} />
            </div>
            <MuiTypography>{name}</MuiTypography>
        </div>
    );
};
ContactNameWithIcon.displayName = 'ContactNameWithIcon';
export default ContactNameWithIcon;
