import { FC, useState, useCallback, useEffect } from 'react';

import { initUodkaCorePublicClient } from '@/utils/UodkaClients/Core';
import { useLanguage } from '@/utils/customHooks';
import Modal from '@/components/0_atom/Modal';
import MarkdownViewer from '@/components/0_atom/MarkdownViewer';
import FetchingText from '@/components/0_atom/FetchingText';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    tosHeading: {},
    tosText: {},
}));

export const TosModal: FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { txt } = useLanguage();
    const [tos, setTos] = useState<string | undefined>(undefined);

    // CALLBACK
    const fetchTos = useCallback(async () => {
        const client = initUodkaCorePublicClient();
        const { tos: fetchedTos } = await client.getLegalTexts();
        setTos(fetchedTos);
    }, []);

    // USEEFFECT
    useEffect(() => {
        fetchTos();
    }, [fetchTos]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} animation={'up'}>
            <MuiTypography variant={'h1'} className={c.tosHeading}>
                {txt({ en: 'Terms of service', ja: '利用規約' })}
            </MuiTypography>
            <div className={c.tosText}>{tos ? <MarkdownViewer textMd={tos} /> : <FetchingText />}</div>
        </Modal>
    );
};
TosModal.displayName = 'TosModal';
