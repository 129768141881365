import { useState, useCallback, useEffect } from 'react';

import {
    UodkaCoreClientForOwner,
    initUodkaCoreClientForOwner,
    MemberInUser,
    ContactAPIForOwner,
    InviteForContact,
} from '@/utils/UodkaClients/Core';
import { BusinessProfile } from '@/utils/UodkaClients/Profile';
import { useUserCtxAbsolutely, MemberInUserWithBusinessProfile } from '@/utils/ctxs';

export type MemberWithContact = {
    member: MemberInUser;
    profile: BusinessProfile | undefined;
    ownerAPI?: {
        coreClient: UodkaCoreClientForOwner;
        contactAPIs: ContactAPIForOwner[];
        isLinked: boolean;
    };
};

const getMemberWithContact = async ({
    memberWithBusinessProfile,
    firebaseUserIdToken,
    inviteForContact,
}: {
    memberWithBusinessProfile: MemberInUserWithBusinessProfile;
    firebaseUserIdToken: string;
    inviteForContact: InviteForContact;
}): Promise<MemberWithContact> => {
    if (memberWithBusinessProfile.member.role !== 'owner') {
        return {
            member: memberWithBusinessProfile.member,
            profile: memberWithBusinessProfile.businessProfile,
        };
    }
    const coreClient = initUodkaCoreClientForOwner({
        clientId: memberWithBusinessProfile.member.business.id,
        firebaseUserIdToken,
    });
    const contactAPIs = await coreClient.getContactAPIs();
    const notLinkedContactAPIs = contactAPIs.filter(({ relatedBusiness }) => !relatedBusiness);
    const isLinked = !!contactAPIs.find(({ relatedBusiness }) => relatedBusiness?.id === inviteForContact.business.id);
    return {
        member: memberWithBusinessProfile.member,
        profile: memberWithBusinessProfile.businessProfile,
        ownerAPI: {
            coreClient,
            contactAPIs: notLinkedContactAPIs,
            isLinked,
        },
    };
};

export const useBusinessWithContacts = ({ inviteForContact }: { inviteForContact: InviteForContact }) => {
    // HOOKS
    const { firebaseUser } = useUserCtxAbsolutely();
    const { memberWithBusinessProfiles } = useUserCtxAbsolutely();
    const [memberWithContacts, setMemberWithContacts] = useState<MemberWithContact[] | undefined>(undefined);

    // CALLBACK
    const fetchMemberWithContacts = useCallback(async () => {
        const excludedSelf = memberWithBusinessProfiles.filter(
            ({ member }) => member.business.id !== inviteForContact.business.id
        );
        const fetchedMemberWithContacts = await Promise.all(
            excludedSelf.map((memberWithBusinessProfile) =>
                getMemberWithContact({
                    memberWithBusinessProfile,
                    firebaseUserIdToken: firebaseUser.idToken,
                    inviteForContact,
                })
            )
        );
        setMemberWithContacts(fetchedMemberWithContacts);
    }, [firebaseUser.idToken, memberWithBusinessProfiles, inviteForContact]);

    // USEEFFECT
    useEffect(() => {
        fetchMemberWithContacts();
    }, [fetchMemberWithContacts]);

    return {
        memberWithContacts,
    };
};
